import tw, { styled } from "twin.macro";

export const CategoryContainer = styled.span`
  ${tw`bg-grey-l4 p-1 text-blue-navy text-xs uppercase rounded-sm font-condensed leading-3 tracking-condensed inline-block`}
`;

export const SportsTab = styled.button<{ active: boolean }>(({ active }) => [
  tw`py-[11px] text-blue-d1`,
  active && tw`font-bold border-b border-blue-d1`,
]);

interface ScrollContainProps {
  offset?: number;
  offsetPixels?: number;
}

export const ScrollContain = styled.div(
  ({ offset, offsetPixels = 300 }: ScrollContainProps) => [
    tw`flex gap-4 transition ease-in-out duration-300 md:flex-row flex-col`,
    offset && `transform:translateX(-${offset * offsetPixels}px)`,
  ]
);

export const ScrollItem = styled.div(({ isHidden }: { isHidden?: boolean }) => [
  isHidden && tw`md:block hidden`,
  tw``,
]);

export const HideAtLg = styled.div(() => [tw`block lg:hidden`]);

export const HideAtMd = styled.div(() => [tw`block md:hidden`]);

export const HideAtSm = styled.div(() => [tw`hidden md:block`]);
