import { SVGProps } from "@/types/svg";
import Svg from "./Svg";
import { colorBlueSvg } from "../../styles/manualVars";

const ArrowLeft = ({
  width = "5",
  height = "10",
  fill = colorBlueSvg,
}: SVGProps) => (
  <Svg width={width} height={height}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M4.853 9.35225C5.048 9.15725 5.048 8.84025 4.853 8.64525L1.207 4.99925L4.853 1.35325C5.048 1.15825 5.048 0.84125 4.853 0.64625C4.658 0.45125 4.341 0.45125 4.146 0.64625L0.146 4.64625C0.048 4.74425 0 4.87225 0 5.00025C0 5.12825 0.049 5.25625 0.146 5.35425L4.146 9.35425C4.341 9.54725 4.657 9.54725 4.853 9.35225Z"
      fill={fill}
    />
  </Svg>
);

export default ArrowLeft;
