import { useCallback } from "react";
import tw, { styled } from "twin.macro";
import Image from "@/components/Image";
import BaseTag from "@/components/Tag";
import Link from "next/link";
import { ARTICLE_LINK_CLICKED } from "@/constants/amplitude";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import { ContentAuthor, Maybe } from "@/graphql/gql/graphql";
import ArticleCardPublish from "./ArticleCardPublish";

type TagType = "none" | "navy" | "grey";

type SpotlightArticleProps = {
  author: ContentAuthor;
  fullwidthMobileImage?: boolean;
  image?: string;
  link: string;
  origin: string;
  publishedOn?: Maybe<string>;
  snippet?: string;
  sportName: string;
  sportSlug?: string;
  tagType?: TagType;
  title: string;
  addBackground?: boolean;
};

const Container = styled("div")(
  ({ addBackground }: { addBackground: boolean }) => [
    addBackground ? tw`bg-white h-full rounded shadow` : "",
    tw`w-full flex flex-col`,
  ]
);

const ImageContainer = styled("div")(
  ({ imageBorder }: { imageBorder?: boolean }) => [
    imageBorder && tw`p-4`,
    !imageBorder && tw`lg:p-4`,
  ]
);
const SpotlightImage = styled(Image)(
  ({ $imageBorder }: { $imageBorder?: boolean }) => [
    $imageBorder ? tw`rounded` : tw`lg:rounded rounded-none`,
    tw`w-full`,
  ]
);

const SpotlightArticle = ({
  author,
  fullwidthMobileImage = false,
  image = "/research/images/fd_logo.webp",
  link,
  publishedOn,
  origin,
  sportName,
  sportSlug,
  snippet,
  tagType = "none",
  title,
  addBackground = false,
}: SpotlightArticleProps) => {
  const { logAmplitudeEvent } = useAmplitude();
  const onClick = useCallback(
    () =>
      logAmplitudeEvent(ARTICLE_LINK_CLICKED, {
        "Link Text": title,
        "Link URL": link,
        Module: origin,
        Competition: sportName,
      }),
    [link, logAmplitudeEvent, origin, sportName, title]
  );

  const TagMap = {
    none: null,
    navy: (
      <BaseTag
        color="navy"
        label={`${sportName} Spotlight`}
        href={`/${sportSlug}`}
      />
    ),
    grey: <BaseTag color="grey" label={sportName} href={`/${sportSlug}`} />,
  };

  return (
    <Container addBackground={addBackground}>
      <Link href={link} prefetch={false} onClick={onClick}>
        <ImageContainer imageBorder={!fullwidthMobileImage}>
          <SpotlightImage
            priority
            $imageBorder={!fullwidthMobileImage}
            alt={title}
            width={800}
            height={450}
            src={image}
            sizes="(min-width: 1440px) 940px,(min-width: 1280px) 640px, 100vw"
          />
        </ImageContainer>
      </Link>

      <div tw="flex flex-col items-start gap-2 p-4 md:gap-1">
        {tagType && sportSlug && TagMap[tagType]}
        <h2 tw="text-base font-bold leading-6 text-grey-d4 md:text-xl">
          <Link prefetch={false} href={link}>
            {title}
          </Link>
        </h2>
        <ArticleCardPublish author={author} date={publishedOn} />
        <div tw="text-sm text-grey-d4 line-clamp-3">{snippet}</div>
      </div>
    </Container>
  );
};

export default SpotlightArticle;
