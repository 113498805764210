import "twin.macro";
import Link from "next/link";
import { Tag as BaseTag } from "@fanduel/og-fe-library";

type TagProps = {
  color: "grey" | "red" | "navy";
  label: string;
  href?: string;
};

const Tag = ({ color, label, href }: TagProps) => {
  if (!href) {
    return <BaseTag color={color} label={label} tw="border border-red-d1" />;
  }

  return (
    <Link href={href}>
      <BaseTag color={color} label={label} tw="border border-red-d1" />
    </Link>
  );
};

export default Tag;
